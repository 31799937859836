/* General styles for LayerModal */
.layer-modal-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;  /* Keep the width at 100% */
  margin: 0 auto;
  padding: 15px 20px;
  justify-content: center;  /* Center the buttons vertically */
  align-items: center;  /* Ensure horizontal centering */
}

.layer-modal-list-container ion-item {
  width: 100%;  /* Ensure the ion-item spans the full width */
  display: flex;
  justify-content: center;
}

.layer-modal-button ion-button {
  width: 100%;  /* Keep the button width to 100% */
  max-width: 95%;  /* Control the maximum width */
  background-color: #007acc;  /* Blue background */
  color: white;  /* White text for contrast */

  /* Smaller font size */
  font-family: Arial, sans-serif;  /* Use a different font */
  font-size: clamp(0.875rem, 2vw + 0.25rem, 1rem);  /* Smaller responsive font size */
  
  font-weight: 500;  /* Slightly lighter font weight */
  border-radius: 8px;  /* Rounded corners */
  line-height: 1.3rem;  /* Maintain good line height */
  text-align: center;  /* Center the text */
  margin: 10px auto;  /* Margin for spacing */
}

.layer-modal-button ion-button:hover {
  background-color: #005fa3;
}

/* Remove any strange height-related issues */
.layer-modal-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer-modal-title {
  font-family: Arial, sans-serif;  /* Use a different font */
  font-size: clamp(1rem, 2vw + 0.5rem, 1.25rem);  /* Responsive title size */
  font-weight: bold;
  text-align: center;
  color: #fff;  /* Better contrast for the title text */
  margin-bottom: 1rem;
}

.layer-modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.layer-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

/* Remove scroll by increasing the modal height */
ion-modal#layer-modal {
  --height: 40%;  /* Increased height to avoid scroll */
  --width: 30%;   /* Increased width for a more spacious layout */
  --border-radius: 12px;
  --background: #333;  /* Dark background for better contrast with text */
}

/* Timestamp Modal Styles */
.timestamp-modal-header h1 {
  font-family: Arial, sans-serif;  /* Use a different font */
  font-size: clamp(1rem, 2vw + 0.5rem, 1.25rem);  /* Responsive header size */
  font-weight: 600;
  margin: 1rem 0;
  text-align: center;
  color: #fff;  /* Better contrast for the timestamp modal title */
}

.timestamp-modal ion-item {
  padding: 0.75rem;
  text-align: center;
  font-size: 0.875rem;  /* Smaller font size for better fit */
  color: #fff;  /* Ensure the timestamp text has good contrast */
}

.timestamp-modal ion-item:hover {
  background-color: #505050;  /* Darker hover color for better contrast */
}

.timestamp-modal-footer {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

ion-modal#timestamp-modal {
  --height: 50%;
  --width: 35%;  /* Increased width for better layout */
  --border-radius: 12px;
  --background: #333;  /* Same dark background for consistency */
}

/* Media Queries for responsiveness */
@media screen and (max-width: 1200px) {
  ion-modal#layer-modal {
    --width: 35%;
  }
  ion-modal#timestamp-modal {
    --width: 40%;
  }
}

@media screen and (max-width: 768px) {
  ion-modal#layer-modal {
    --width: 50%;
  }
  ion-modal#timestamp-modal {
    --width: 60%;
  }
  .layer-modal-button ion-button {
    font-size: clamp(0.75rem, 2vw + 0.25rem, 0.875rem);  /* Smaller size for medium screens */
  }
}

@media screen and (max-width: 480px) {
  ion-modal#layer-modal {
    --width: 70%;
  }
  ion-modal#timestamp-modal {
    --width: 80%;
  }
  .layer-modal-button ion-button {
    font-size: clamp(0.625rem, 3vw + 0.25rem, 0.75rem);  /* Smaller size for mobile devices */
  }
}
