.vesselTypeItem {
  margin-top: 60px;
}

.selectStyle1 {
  background-color: #808080;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  margin-left: 90px;
}

.selectStyle2 {
  background-color: #808080;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  margin-left: 95px;
}

.selectStyle3 {
  background-color: #808080;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  margin-left: 25px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleStyle {
  margin-right: 400px;
}

.closeButton {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  transition: color 0.3s;
  font-weight: bold;
}

/* Media Queries for Responsiveness */

@media screen and (max-width: 1200px) {
  .titleStyle {
    margin-right: 28rem;
    /* Adjust margin for medium-sized screens */
  }
}

@media screen and (max-width: 768px) {
  .titleStyle {
    margin-right: 20rem;
    /* Adjust margin for small screens */
  }
}

@media screen and (max-width: 480px) {
  .titleStyle {
    margin-right: 12rem;
    /* Further adjust margin for very small screens */
  }
}